import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authServices from '../../services/auth.services';
import { PURGE } from 'redux-persist';

export const login = createAsyncThunk('login', async (body, { rejectWithValue }) => {
    try {
        const response = await authServices.login(body);
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const getProfileInformation = createAsyncThunk('getProfileInformation', async (_, { rejectWithValue }) => {
    try {
        const response = await authServices.profileInformation();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const updateProfileInformation = createAsyncThunk('updateProfileInformation', async (data, { dispatch, rejectWithValue }) => {
    try {
        const response = await authServices.updateProfileInformation(data);
        dispatch(getProfileInformation())
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

const initialState: any = {
    token: null,
    role: "user",
    profileInformation: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null
            state.role = null
            state.userInformation = null
        },
        handleProfileInformationChange: (state, action) => {
            state.profileInformation = { ...state.profileInformation, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: any) => {
            localStorage.setItem('persist:crossTabs', 'false');
            state.token = action?.payload?.data?.data?.token;
        });
        builder.addCase(getProfileInformation.fulfilled, (state, action: any) => {
            state.profileInformation = action?.payload?.data?.data;
        });
        builder.addCase(PURGE, () => {
            localStorage.setItem('persist:crossTabs', 'true');
            return { ...initialState };
        });
    },
});

export const { logout, handleProfileInformationChange } = authSlice.actions;
