import PrivateRoute from "./authentication/PrivateRoute";
import { Routes, Route } from "react-router";
import {
    Login,
    Dashboard,
    Users,
    AddUser,
    Edit,
    User,
    NotFound,
    Trainings,
    AddTraining,
    EditTraining,
    AddBundle,
    EditBundles,
    TrackLogs,
    Purchases,
    Profile
} from "./pages"
import { useSelector } from "react-redux";

const AppRoutes = () => {
    // const { role } = useSelector(state => state.auth) gonna add on next phase
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/track-logs" element={<TrackLogs />} />
                <Route path="/purchases" element={<Purchases />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/edit/:id" element={<Edit />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/users/:id" element={<User />} />
                <Route path="/trainings" element={<Trainings />} />
                <Route path="/add-training" element={<AddTraining />} />
                <Route path="/trainings/edit/:id" element={<EditTraining />} />
                <Route path="/add-bundle" element={<AddBundle />} />
                <Route path="/bundles/edit/:id" element={<EditBundles />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes;
