import { api } from './api';

const purchases = () => {
    return api({
        url: '/userTrainingPrices',
        method: 'GET'
    });
};

const deletePurchase = (id: any) => {
    return api({
        url: `/userTrainingPrices/${id}`,
        method: 'DELETE'
    });
};

const addPurchase = (data: any) => {
    return api({
        url: `/userTrainingPrices`,
        method: 'POST',
        data
    });
};

const purchaseServices = {
    purchases, deletePurchase, addPurchase
};

export default purchaseServices;
