import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import memberServices from "../../services/member.services";
import { IMember } from '../../models/Member';
import { ILastLogsPayload, IMembersPayload, ILogsPayload, IMemberPayload } from '../../models/Payload';
import { IMemberInitialState } from '../../models/InitialState';
import { IId } from '../../models/types';

export const getLastRegisters = createAsyncThunk<IMembersPayload>('getLastRegisters', async (_, { rejectWithValue }) => {
    try {
        const response = await memberServices.lastRegisters();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const getLastLogs = createAsyncThunk<ILastLogsPayload>('getLastLogs', async (_, { rejectWithValue }) => {
    try {
        const response = await memberServices.lastLogs();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const getLogs = createAsyncThunk<ILogsPayload>('getLogs', async (_, { rejectWithValue }) => {
    try {
        const response = await memberServices.trackLogs();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const getMembers = createAsyncThunk<IMembersPayload>('getMembers', async (_, { rejectWithValue }) => {
    try {
        const response = await memberServices.members();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const getMember = createAsyncThunk<IMemberPayload, IId, { rejectValue: string }>('getMember', async (id, thunkApi) => {
    try {
        const response = await memberServices.member(id);
        return response;
    } catch (error: any) {
        return thunkApi.rejectWithValue(error?.data);
    }
});

export const addMember = createAsyncThunk('addMember', async (data: IMember, { dispatch, rejectWithValue }) => {
    try {
        const response = await memberServices.addMember({ ...data });
        dispatch(getMembers());
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const deleteMember = createAsyncThunk('deleteMember', async (id: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await memberServices.deleteMember(id);
        dispatch(getMembers());
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const editMember = createAsyncThunk('editMember', async (data: IMember, { rejectWithValue }) => {
    try {
        let date: any = new Date(data?.birth_date)
        date = parseInt(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
        const response = await memberServices.editMember(data);
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const initialMember: IMember = {
    name: "",
    surname: "",
    gender: "",
    phone: "",
    card_id: "",
    email: "",
    birth_date: new Date(),
    city: "",
    district: "",
    neighbourhood: "",
    street: "",
    building_no: "",
    block: "",
    floor: "",
    room_no: "",
    blood_type: "",
    height: "",
    weight: "",
    activity: "",
    disease: "",
    kinship_name: "",
    kinship_phone: "",
    kinship_level: "",
    kinship_short_address: "",
    unlimited_entry: false
}

const initialState: IMemberInitialState = {
    lastRegisters: [],
    lastLogs: [],
    logs: [],
    members: [],
    member: initialMember,
    memberDetail: initialMember
};

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        editMemberField: (state, action) => {
            state.member = { ...state.member, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLastRegisters.fulfilled, (state, action: PayloadAction<IMembersPayload>) => {
            state.lastRegisters = action.payload.data.data;
        });
        builder.addCase(getLastLogs.fulfilled, (state, action: PayloadAction<ILastLogsPayload>) => {
            state.lastLogs = action.payload.data.data;
        });
        builder.addCase(getLogs.fulfilled, (state, action: PayloadAction<ILogsPayload>) => {
            state.logs = action.payload.data.data;
        });
        builder.addCase(getMembers.fulfilled, (state, action: PayloadAction<IMembersPayload>) => {
            state.members = action.payload.data.data;
        });
        builder.addCase(getMember.fulfilled, (state, action) => {
            state.member = initialMember;
            const response = action.payload.data.data;
            let date: any = new Date(response?.birth_date);
            // let newDate = new Date(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`);
            state.member = { ...state.member, ...response, birth_date: date };
            for (const [key, value] of Object.entries(state.member)) {
                if (!value) {
                    state.member = {...state.member, [key]: ""}
                }
            }
            date = date.getDate() + "/" + parseInt(date.getMonth() + 1) + "/" + date.getFullYear();
            state.memberDetail = { ...response, birth_date: date };
        });
    },
});

export const { editMemberField } = memberSlice.actions;