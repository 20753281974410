import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import trainingServices from '../../services/training.services';

export const getTrainings = createAsyncThunk('getTrainings', async (_, { rejectWithValue }) => {
    try {
        const response = await trainingServices.trainings();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const getTraining = createAsyncThunk('getTraining', async (id: string, { rejectWithValue }) => {
    try {
        const response = await trainingServices.training(id);
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const addTraining = createAsyncThunk('addTraining', async (data: any, { rejectWithValue }) => {
    try {
        const response = await trainingServices.addTraining({ ...data });
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const editTraining = createAsyncThunk('editTraining', async ({ data, id }: any, { dispatch, rejectWithValue }) => {
    try {
        const response = await trainingServices.editTraining({ data, id });
        dispatch(getTrainings())
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const deleteTraining = createAsyncThunk('deleteTraining', async (id: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await trainingServices.deleteTraining(id);
        dispatch(getTrainings())
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const addBundle = createAsyncThunk('addBundle', async (data: any, { rejectWithValue }) => {
    try {
        const response = await trainingServices.addBundle({ ...data });
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const editBundle = createAsyncThunk('editBundle', async ({ data, id }: any, { rejectWithValue }) => {
    try {
        const response = await trainingServices.editBundle({ data, id });
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

const initialState: any = {
    trainings: [],
    trainingPrices: [],
    trainingSchedules: [],
    trainingName: ""
};

export const trainingSlice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        editTrainingName: (state, action: any) => {
            state.trainingName = action.payload;
        },
        addTrainingPrice: (state, action) => {
            state.trainingPrices = [...state.trainingPrices, action.payload];
        },
        editTrainingPrice: (state, action) => {
            state.trainingPrices = state.trainingPrices.map((item: any) => {
                return (item.id === action.payload.id ? { ...action.payload } : { ...item })
            })
        },
        deleteTrainingPrice: (state, action) => {
            state.trainingPrices = state.trainingPrices.filter((item: any) => item.id !== action.payload);
        },
        addTrainingSchedule: (state, action) => {
            state.trainingSchedules = [...state.trainingSchedules, action.payload];
        },
        editTrainingSchedule: (state, action) => {
            state.trainingSchedules = state.trainingSchedules.map((item: any) => {
                return (item.id === action.payload.id ? { ...action.payload } : { ...item })
            })
        },
        deleteTrainingSchedule: (state, action) => {
            state.trainingSchedules = state.trainingSchedules.filter((item: any) => item.id !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTrainings.fulfilled, (state, action: any) => {
            state.trainings = action.payload.data.data;
        });
        builder.addCase(getTraining.fulfilled, (state, action: any) => {
            const response = action.payload.data.data
            let newArray = response.training_schedules
            newArray.map((item: any) => {
                const date = new Date();
                item.start_time = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${item.start_time}`);
                item.finish_time = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${item.finish_time}`);
            });
            state.trainingSchedules = newArray;
            state.trainingPrices = response.training_prices;
            state.trainingName = response.title;
        });
    },
});

export const { editTrainingName, addTrainingPrice, editTrainingPrice, deleteTrainingPrice, addTrainingSchedule, editTrainingSchedule, deleteTrainingSchedule } = trainingSlice.actions;
