import { useState } from 'react';
import AdminNavbar from './AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import H6 from '@material-tailwind/react/Heading6';
import { Link, useLocation } from "react-router-dom";
import TrainingIcon from "../assets/img/sneaker.png"
import Tourniquet from "../assets/img/turnstiles.png"
import cookie from "js-cookie";
import {useDispatch} from "react-redux";
import {logout} from "../store/auth/authSlice";

export default function Sidebar() {
    const dispatch = useDispatch()
    const [showSidebar, setShowSidebar] = useState('-left-64');
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const activeLink = "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <a
                        href=""
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 text-center w-full inline-block"
                    >
                        <H6 color="gray">Sirius</H6>
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <div className="flex-col min-w-full flex">
                            <div className="rounded-lg mb-4">
                                <Link
                                    to="/dashboard"
                                    className={`flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg ${location.pathname === "/admin/dashboard" ? activeLink : location.pathname === "/admin" ? activeLink : location.pathname === "/" ? activeLink : ""}`}
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Anasayfa
                                </Link>
                            </div>
                            <div className="rounded-lg mb-2">
                                <Link
                                    to="/track-logs"
                                    className={`flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg ${splitLocation[2] === "track-logs" ? "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md" : ""}`}
                                >
                                    <img className={`${splitLocation[2] === "track-logs" ? "filter invert" : ""}`} src={Tourniquet} alt="" />
                                    Turnike
                                </Link>
                            </div>
                            <div className="rounded-lg mb-2">
                                <Link
                                    to="/trainings"
                                    className={`flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg ${splitLocation[2] === "trainings" ? "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md" : ""}`}
                                >
                                    <img className={`${splitLocation[2] === "trainings" ? "filter invert" : ""}`} src={TrainingIcon} alt="" />
                                    Antrenmanlar
                                </Link>
                            </div>
                            <div className="rounded-lg mb-2">
                                <Link
                                    to="/users"
                                    className={`flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg ${splitLocation[2] === "users" ? "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md" : ""}`}
                                >
                                    <Icon name="people_outline" size="2xl" />
                                    Kullanıcılar
                                </Link>
                            </div>
                            <div className="rounded-lg mb-2">
                                <Link
                                    to="/purchases"
                                    className={`flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg ${splitLocation[2] === "purchases" ? "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md" : ""}`}
                                >
                                    <Icon name="shopping_bag" size="2xl" />
                                    Satışlar
                                </Link>
                            </div>
                            <div className="rounded-lg mb-2">
                                <Link
                                    to="/profile"
                                    className={`flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg ${splitLocation[2] === "profile" ? "bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md" : ""}`}
                                >
                                    <Icon name="account_circle" size="2xl" />
                                    Profil
                                </Link>
                            </div>
                        </div>

                        <ul className="flex-col min-w-full flex list-none absolute bottom-0">
                            <li className="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 px-4 rounded-lg text-white mb-2">
                                <div
                                    onClick={() => {
                                        dispatch(logout())
                                        cookie.remove("token")
                                        window.location.replace('/login')
                                    }}
                                    className="flex items-center gap-4 text-sm font-light py-3 cursor-pointer"
                                >
                                    <Icon name="logout" size="2xl" />
                                    Çıkış
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
