import { api } from './api';

const trainings = () => {
    return api({
        url: '/trainings',
        method: 'GET'
    });
};

const training = (id: string) => {
    return api({
        url: `/trainings/${id}`,
        method: 'GET'
    });
};

const editTraining = ({ data, id }: any) => {
    return api({
        url: `/trainings/${id}`,
        method: 'PATCH',
        data
    });
};

const addTraining = (data: any) => {
    return api({
        url: `/trainings`,
        method: 'POST',
        data
    });
};

const deleteTraining = (id: string) => {
    return api({
        url: `/trainings/${id}`,
        method: 'DELETE'
    });
};

const addBundle = (data: any) => {
    return api({
        url: `/bundles`,
        method: 'POST',
        data
    });
};

const editBundle = ({ data, id }: any) => {
    return api({
        url: `/trainings/${id}`,
        method: 'PATCH',
        data
    });
};

const trainingServices = {
    trainings, training, addTraining, editTraining, deleteTraining, addBundle, editBundle
};

export default trainingServices;
