import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import purchaseServices from '../../services/purchase.services';
import dayjs from "dayjs"

export const getPurchases = createAsyncThunk('getPurchases', async (_, { rejectWithValue }) => {
    try {
        const response = await purchaseServices.purchases();
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const deletePurchase = createAsyncThunk('deletePurchase', async (id, { dispatch, rejectWithValue }) => {
    try {
        const response = await purchaseServices.deletePurchase(id);
        dispatch(getPurchases())
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

export const addPurchase = createAsyncThunk('addPurchase', async (data: any, { rejectWithValue }) => {
    try {
        const response = await purchaseServices.addPurchase({ ...data });
        return response;
    } catch (error: any) {
        return rejectWithValue(error?.data);
    }
});

const initialState = {
    purchases: [],
};

export const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        getActiveUsers: (state) => {
            state.purchases = state.purchases.filter((item: any) => dayjs(item.expires_at.slice(0, 10).split(".").reverse().join(".")) > dayjs());
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPurchases.fulfilled, (state, action: any) => {
            state.purchases = action.payload.data.data;
        });
    },
});

export const { getActiveUsers } = purchaseSlice.actions;
