import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AdminLayout from '../layouts/admin/AdminLayout';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
    const { token } = useSelector(state => state?.auth);
    const location = useLocation();

    return token ? (<AdminLayout><Outlet /></AdminLayout>) : <Navigate replace state={{ from: location }} to="/" />
}

export default PrivateRoute;
