import AppRoutes from "./AppRoutes";
import { Suspense, useEffect } from "react";
import { api } from './services/api';
import { BrowserRouter } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAppSelector } from "./hooks/redux-hooks";

const App = () => {
  const { token } = useAppSelector((state) => state?.auth);
  useEffect(() => {
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}` || '';
    } else {
      delete api.defaults.headers.common['Authorization'];
    }
  }, [token]);
  return (
    <Suspense fallback={<CircularProgress />}>
      <BrowserRouter basename="/">
        <AppRoutes />
      </BrowserRouter>
    </Suspense>
  )
}

export default App;
