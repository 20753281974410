import { lazy } from 'react';

const Login = lazy(() =>
    import('./login/Login').then(({ default: Component }) => ({ default: Component })),
);

const Dashboard = lazy(() =>
    import('./dashboard/Dashboard').then(({ default: Component }) => ({ default: Component })),
);

const Users = lazy(() =>
    import('./users/Users').then(({ default: Component }) => ({ default: Component })),
);

const AddUser = lazy(() =>
    import('./users/AddUser').then(({ default: Component }) => ({ default: Component })),
);

const Edit = lazy(() =>
    import('./users/EditUser').then(({ default: Component }) => ({ default: Component })),
);

const User = lazy(() =>
    import('./users/User').then(({ default: Component }) => ({ default: Component })),
);

const NotFound = lazy(() =>
    import('./notfound/NotFound').then(({ default: Component }) => ({ default: Component })),
);

const Trainings = lazy(() =>
    import('./trainings/Trainings').then(({ default: Component }) => ({ default: Component })),
);

const AddTraining = lazy(() =>
    import('./trainings/AddTraining').then(({ default: Component }) => ({ default: Component })),
);

const EditTraining = lazy(() =>
    import('./trainings/EditTraining').then(({ default: Component }) => ({ default: Component })),
);

const AddBundle = lazy(() =>
    import('./bundles/AddBundle').then(({ default: Component }) => ({ default: Component })),
);

const EditBundles = lazy(() =>
    import('./bundles/EditBundles').then(({ default: Component }) => ({ default: Component })),
);

const TrackLogs = lazy(() =>
    import('./trackusers/TrackLogs').then(({ default: Component }) => ({ default: Component })),
);

const Purchases = lazy(() =>
    import('./purchases/Purchases').then(({ default: Component }) => ({ default: Component })),
);

const Profile = lazy(() =>
    import('./profile/Profile').then(({ default: Component }) => ({ default: Component })),
);

export {
    Login,
    Dashboard,
    Users,
    AddUser,
    Edit,
    User,
    NotFound,
    Trainings,
    AddTraining,
    EditTraining,
    AddBundle,
    EditBundles,
    TrackLogs,
    Purchases,
    Profile
};
