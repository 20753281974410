import { AxiosPromise } from 'axios';
import { IMember } from '../models/Member';
import { IMemberPayload } from '../models/Payload';
import { api } from './api';

const lastRegisters = () => {
    return api({
        url: '/last_registers/4',
        method: 'GET'
    });
};

const lastLogs = () => {
    return api({
        url: '/last_logs/4',
        method: 'GET'
    });
};

const trackLogs = () => {
    return api({
        url: '/logs',
        method: 'GET'
    })
}

const members = () => {
    return api({
        url: '/users',
        method: 'GET'
    })
}

const member = (id: string) => {
    return api({
        url: `users/${id}`,
        method: 'GET'
    })
}

const addMember = (data: any) => {
    return api({
        url: '/users',
        method: 'POST',
        data
    })
}

const deleteMember = (id: any) => {
    return api({
        url: `/users/${id}`,
        method: 'DELETE'
    })
}

const editMember = (data: IMember) => {
    return api({
        url: `/users/${data.id}`,
        method: 'PATCH',
        data
    })
}

const memberServices = {
    lastRegisters, lastLogs, trackLogs, members, member, addMember, deleteMember, editMember
};

export default memberServices;
