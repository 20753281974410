import { api } from './api';

const login = (data: any) => {
    return api({
        url: '/login',
        method: 'POST',
        data,
    });
};

const profileInformation = () => {
    return api({
        url: '/profile',
        method: 'GET',
    });
};

const updateProfileInformation = (data: any) => {
    return api({
        url: '/profile',
        method: 'PATCH',
        data,
    });
};

const authServices = {
    login, profileInformation, updateProfileInformation
};

export default authServices;
