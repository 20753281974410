import React from 'react';
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import 'react-toastify/dist/ReactToastify.css'

function AdminLayout() {

    return (
        <div>
            <Sidebar />
            <div className="md:ml-64 min-h-screen flex flex-col justify-between">
                <Outlet />
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
}

export default AdminLayout;
