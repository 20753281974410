import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { AnyAction, combineReducers } from 'redux';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { authSlice } from "./auth/authSlice";
import { trainingSlice } from "./training/trainingSlice";
import { purchaseSlice } from './purchase/purchaseSlice';
import { memberSlice } from "./member/memberSlice";

const reducers = combineReducers({
    auth: authSlice.reducer,
    training: trainingSlice.reducer,
    purchase: purchaseSlice.reducer,
    member: memberSlice.reducer
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    transforms: [
        encryptTransform({
            secretKey: 'smart-card-key-for-redux-persist',
            onError: function (error) {
                throw new Error(error?.message);
            },
        }),
    ],
};

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === FLUSH) {
        storage.removeItem('persist:root');
        return reducers(undefined, action);
    }

    return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer) as typeof rootReducer;

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
        });
    }
});

const persist = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { store, persist };
